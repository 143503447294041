import React from "react"

export default () => (
  <footer className="footer">
    <p>© {new Date().getFullYear()}</p>
    <p>
      Owned and managed by <a href="https://sortedbyanna.com">Sorted By Anna</a>
    </p>
    <p>
      Built by <a href="https://progresslabs.co">Progress Labs</a>
    </p>
    <p>Designed by Steve Ng</p>
  </footer>
)
