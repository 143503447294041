import React from "react"

const Header = () => (
  <header className="header">
    <div className="layout">
      <aside className="aside">
        <h2>Tipped</h2>
        <p>
          A directory of productivity and home oraganization businesses impacted
          by COVID-19 in the United States.
        </p>
      </aside>
      <div className="main">
        <h1 style={{ textAlign: "center" }}>
          Support your local home businesses.
        </h1>
      </div>
    </div>
  </header>
)

export default Header
